import React, { useState, useEffect, forwardRef } from 'react';
import posthog from 'posthog-js';
import { useMediaQuery } from 'react-responsive';
import { motion, AnimatePresence } from 'framer-motion';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa'; // Change the imported icons

import * as Sentry from "@sentry/react"; // Import Sentry if not already imported

import sendPostRequest from '../scripts/Requests'; // Assuming you have this utility function
import { SortButtons, SmartSort } from './PaperDisplayControls';

// Add these animation variants at the top of the file, after imports
const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 0 }
};

const detailsVariants = {
  enter: { x: 20, opacity: 0 },
  center: { x: 0, opacity: 1 },
  exit: { x: -20, opacity: 0 },
};



/* -------------------------------------------------------------------------- */
/*                                 PAPERS LIST                                */
/* -------------------------------------------------------------------------- */
const PapersListElement = ({ paper, isPlaceholder, onClick, isSelected, isBusy, animate, animationDuration, variants, highlightScore }) => {
    const Element = animate ? motion.div : 'div';
    
    return (
      <Element 
        variants={animate ? variants : undefined}
        className={`papers-list-element ${isPlaceholder ? 'placeholder' : ''} ${isSelected ? 'selected' : ''} ${isBusy ? 'busy' : ''}`} 
        onClick={() => !isBusy && onClick(paper)}
        transition={{ duration: animationDuration }}
      >
        <div className={`paper-details-left ${isPlaceholder ? "placeholder" : ""}`}>
            {!isPlaceholder && (
                <div className="paper-score">
                    <strong style={{color: highlightScore ? 'var(--highlight-color)' : 'var(--default-score-color)', transition: 'color 0.5s ease-in-out'}}>{paper.score !== undefined ? Math.round(parseFloat(paper.score) * 100) : null}<span className="faint">/100</span></strong>
                    <span className="label">score</span>
                </div>
            )}
            
            <div className={`paper-citations`}>    
                <strong>{isPlaceholder ? "100" : paper.n_citations}</strong>
                <span className="label">citations</span>
            </div>
            <div className={`paper-year`}>
                <strong>{isPlaceholder ? "2024" : paper.year}</strong>
                <span className="label">year</span>
            </div>
        </div>


        <div className={`paper-details-right ${isPlaceholder ? "placeholder" : ""}`}>
            <div className={`paper-title-level ${isPlaceholder ? "placeholder" : ""}`}>
                <div className="paper-title">
                    {isPlaceholder ? "Paper Title" : paper.title}
                </div>
            </div>
            <div className={`paper-journal ${isPlaceholder ? "placeholder" : ""}`}>
                {isPlaceholder ? "A journal with a long name" : paper.journal}
            </div>
        </div>
      </Element>
    );
};

const PapersList = ({ papers, onPaperSelect, selectedPaperId, isBusy, hideControls, animate, animationDuration }) => {
    const ListContainer = animate ? motion.div : 'div';
    
    const paperElements = Array.isArray(papers) && papers.length > 0
      ? papers.map((paper, i) => (
          <PapersListElement 
            key={paper.paperId || i}
            paper={paper}
            isPlaceholder={false}
            onClick={onPaperSelect}
            isSelected={paper === selectedPaperId}
            isBusy={isBusy}
            animate={animate}
            animationDuration={animationDuration}
          />
        ))
      : Array(8).fill().map((_, i) => (
          <PapersListElement 
            key={i} 
            isPlaceholder={true} 
            onClick={() => {}} 
            isBusy={isBusy} 
            animate={animate} 
            animationDuration={animationDuration} 
          />
        ));

    return (
      <div className={`papers-list-container ${hideControls ? "hide-controls" : ""}`}>
        <ListContainer
          className="papers-list"
          variants={animate ? listVariants : undefined}
          initial={animate ? "hidden" : undefined}
          animate={animate ? "visible" : undefined}
          transition={{ staggerChildren: animationDuration * 0.1 }}
        >
          {paperElements}
        </ListContainer>
      </div>
    );
};


/* -------------------------------------------------------------------------- */
/*                                PAPER DETAILS                               */
/* -------------------------------------------------------------------------- */
const PaperDetails = ({ paper, hideControls, animate }) => {
    const placeholderPaper = {
        id: 'placeholder',
        title: "Sample Paper Title",
        authors: "John Doe, Jane Smith",
        year: "2023",
        n_citations: "42",
        journal: "Journal of Example Studies",
        url: "",
        tldr: "This is a placeholder TLDR for when no paper is selected.",
        abstract: "This is a placeholder abstract for when no paper is selected. It provides a brief overview of what the paper would be about if it were a real paper."
    };

    const displayPaper = paper || placeholderPaper;
    const isPlaceholder = !paper;

    const hasTLDR = displayPaper.tldr !== "No tldr.";
    const hasAbstract = displayPaper.abstract !== "No abstract.";

    const logPaperOpened = () => {
        if (!isPlaceholder) {
            posthog.capture("paper_url_clicked", {
                paper_id: displayPaper.id,
                paper_title: displayPaper.title,
                paper_authors: displayPaper.authors,
                paper_year: displayPaper.year,
                paper_journal: displayPaper.journal,
                paper_url: displayPaper.url,
                citations: displayPaper.n_citations,
                score: displayPaper.score,
            });
        }
    };

    const DetailsContainer = animate ? motion.div : 'div';

    return (
      <AnimatePresence mode="wait">
        <DetailsContainer
          key={displayPaper.title}
          variants={animate ? detailsVariants : undefined}
          initial={animate ? "enter" : undefined}
          animate={animate ? "center" : undefined}
          exit={animate ? "exit" : undefined}
          transition={animate ? { type: "tween", duration: 0.3 } : undefined}
          className={`paper-details ${hideControls ? "hide-controls" : ""}`}
        >
          <div className={`${isPlaceholder ? "placeholder" : ""} pd-title`} onClick={logPaperOpened}>
              {displayPaper.url ? (
                  <a href={displayPaper.url} target="_blank" rel="noopener noreferrer">{displayPaper.title}</a>
              ) : (
                  displayPaper.title
              )}
          </div>
          <div className={`${isPlaceholder ? "placeholder" : ""} pd-authors`}>{displayPaper.authors}</div>

          <div className="pd-level">
              <div className={`${isPlaceholder ? "placeholder" : ""} pd-level-left`}>
              <div className={`${isPlaceholder ? "placeholder" : ""} pd-year`}><strong>{displayPaper.year}</strong></div>
              <div className={`${isPlaceholder ? "placeholder" : ""} pd-citations`}><strong>{displayPaper.n_citations}</strong> citations</div>
              </div>
              <div className={`${isPlaceholder ? "placeholder" : ""} pd-journal`}><i>{displayPaper.journal}</i></div>
          </div>
          {hasTLDR && <div className="pd-section">
              <div className={`pd-section-title ${isPlaceholder ? "placeholder" : ""}`}>TLDR</div>
              <div className={`pd-section-text ${isPlaceholder ? "placeholder" : ""}`}>{displayPaper.tldr}</div>
          </div>
          }

          {hasAbstract && <div className="pd-section">
              <div className={`pd-section-title ${isPlaceholder ? "placeholder" : ""}`}>Abstract</div>
              <div className={`pd-section-text ${isPlaceholder ? "placeholder" : ""}`}>{displayPaper.abstract}</div>
          </div>
          }
        </DetailsContainer>
      </AnimatePresence>
    );
};



/* -------------------------------------------------------------------------- */
/*                               PAPERS DISPLAY                               */
/* -------------------------------------------------------------------------- */



const PapersDisplayLeft = ({ viewState, papers, onPaperSelect, selectedPaperId, isBusy, handleSort, sortOrder, sortDirection, handleToggleSortDirection, handleSmartSort, hideControls, animate, animationDuration }) => {
    return (
        <div className="papers-display-left">
            {!hideControls && <div className="title-in-the-background">Recommended papers</div>}
            {!hideControls && (
                <SortButtons
                    viewState={viewState}
                    papers={papers}
                    onSort={handleSort}
                    sortOrder={sortOrder}
                    sortDirection={sortDirection}
                    onToggleSortDirection={handleToggleSortDirection}
                    isBusy={isBusy}
                    handleSmartSort={handleSmartSort}
                />
            )}
            <PapersList papers={papers} onPaperSelect={onPaperSelect} selectedPaperId={selectedPaperId} isBusy={isBusy} hideControls={hideControls} animate={animate} animationDuration={animationDuration}/>
            {!hideControls && (
                <div className="papers-count">
                    <strong>{papers.length}</strong> papers
                </div>
            )}
        </div>
    );
};



const PapersDisplayRight = ({ selectedPaper, hideControls, animate, animationDuration }) => {
    const [vote, setVote] = useState(null);

    // Reset vote when selectedPaper changes
    useEffect(() => {
        setVote(null);
    }, [selectedPaper]);

    const handleVote = (voteType) => {
        setVote(voteType);
        posthog.capture("paper_vote", {
            paper_id: selectedPaper.paperId,
            paper_title: selectedPaper.title,
            vote_type: voteType,
        });
    };

    return (
        <div className={`papers-display-right ${hideControls ? "hide-controls" : ""}`}>
            {!hideControls && <div className="title-in-the-background">Paper details</div>}
            <PaperDetails paper={selectedPaper} hideControls={hideControls} animate={animate} />
            {selectedPaper && !hideControls && (
                <div className="pd-vote-buttons">
                    <button 
                        className={`pd-vote-button left ${vote === 'upvote' ? 'active' : ''}`} 
                        onClick={() => handleVote('upvote')}
                        aria-label="Upvote"
                    >
                        <FaArrowUp /> {/* Changed icon */}
                    </button>
                    <button 
                        className={`pd-vote-button right ${vote === 'downvote' ? 'active' : ''}`} 
                        onClick={() => handleVote('downvote')}
                        aria-label="Downvote"
                    >
                        <FaArrowDown /> {/* Changed icon */}
                    </button>
                </div>
            )}
        </div>
    );
};


const PapersDisplay = ({ 
    viewState,
    papers: initialPapers, 
    style, 
    isBusy, 
    smartSortPlaceholder, 
    description,
    setViewState,
    hideControls = false,
    hideDetails = false,
    animate = false,
    animationDuration = 0.3, // New prop with default value
}) => {
    const [selectedPaper, setSelectedPaper] = useState(null);
    const [papers, setPapers] = useState(initialPapers);
    const [sortOrder, setSortOrder] = useState('relevance');
    const [sortDirection, setSortDirection] = useState('desc');
    const [isSmartSorting, setIsSmartSorting] = useState(false);
    const [localBusy, setLocalBusy] = useState(false);


    useEffect(() => {
        setPapers(initialPapers);
        setSelectedPaper(null);
        setSortOrder('relevance');
        setSortDirection('desc');
    }, [initialPapers]);


    const handlePaperSelect = (paper) => {
        if (isBusy) return; // Prevent selection when busy
        setSelectedPaper(paper);
        posthog.capture("paper_selected", {
            paper_id: paper.paperId,
            paper_title: paper.title,
            paper_authors: paper.authors,
            paper_year: paper.year,
            paper_journal: paper.journal,
            paper_url: paper.url,
            citations: paper.n_citations,
            score: paper.score,
        });
    };

    const handleSort = (sortBy, direction = null) => {
        if (isBusy || localBusy) return;
        
        setLocalBusy(true);
        setViewState(prevState => ({ ...prevState, isBusy: true }));

        let sortedPapers = [...papers];
        if (sortBy === 'relevance') {
            sortedPapers = [...initialPapers]; // Reset to original order
            if (direction === 'asc') {
                sortedPapers.reverse(); // Invert the order if descending
            }
        } else {
            sortedPapers.sort((a, b) => {
                if (sortBy === 'year') {
                    return direction === 'asc' ? a.year - b.year : b.year - a.year;
                } else if (sortBy === 'citations') {
                    return direction === 'asc' ? a.n_citations - b.n_citations : b.n_citations - a.n_citations;
                }
                return 0;
            });
        }
        setPapers(sortedPapers);
        setSortOrder(sortBy);
        setLocalBusy(false);
        setViewState(prevState => ({ ...prevState, isBusy: false }));
    };

    const handleToggleSortDirection = (direction) => {
        if (isBusy) return;
        setSortDirection(direction);
        handleSort(sortOrder, direction);
    };

    const handleSmartSort = async (inputText) => {
        if (isSmartSorting || isBusy || localBusy) return;
        
        setIsSmartSorting(true);
        setLocalBusy(true);
        setViewState(prevState => ({ ...prevState, isBusy: true }));

        console.log('Applying smart sort with input:', inputText);
        posthog.capture('try_app_smart_sort', { prompt: inputText });

        const payload = {
            papers: papers,
            prompt: inputText,
        };

        try {
            const response = await sendPostRequest(
                '/backend/score-by-llm',
                JSON.stringify(payload),
                'score-by-llm'
            );

            if (response && response.papers) {
                setPapers(response.papers);
                setSortOrder('relevance');
                setSortDirection('desc');
            } else {
                console.error('Error applying smart sort:', response);
                Sentry.captureException(response);
                posthog.capture('try_app_error_smart_sort', { message: response.error });
            }
        } catch (error) {
            console.error('Error applying smart sort:', error);
            Sentry.captureException(error);
            posthog.capture('try_app_error_smart_sort', { message: error.message });
        } finally {
            setIsSmartSorting(false);
            setLocalBusy(false);
            setViewState(prevState => ({ ...prevState, isBusy: false }));
        }
    };
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

    return (
        <div className="papers-display" style={style}>
            {isTabletOrMobile ? (
                <>
                <PapersDisplayLeft 
                    viewState={viewState}
                    papers={papers}
                    onPaperSelect={handlePaperSelect}
                    selectedPaperId={selectedPaper}
                    isBusy={isBusy || localBusy}
                    handleSort={handleSort}
                    sortOrder={sortOrder}
                    sortDirection={sortDirection}
                    handleToggleSortDirection={handleToggleSortDirection}
                    handleSmartSort={handleSmartSort}
                    smartSortPlaceholder={smartSortPlaceholder}
                    isSmartSorting={isSmartSorting}
                    hideControls={hideControls}
                    animate={animate}
                    animationDuration={animationDuration}
                />

                    {!hideDetails && <PapersDisplayRight
                        selectedPaper={selectedPaper}
                        handleSmartSort={handleSmartSort}
                        smartSortPlaceholder={smartSortPlaceholder}
                        isSmartSorting={isSmartSorting}
                        hideControls={hideControls}
                        animate={animate}
                        animationDuration={animationDuration}
                    />}
                </>
            ) : (
                <div className="papers-display-desktop">
                    <PapersDisplayLeft 
                        viewState={viewState}
                        papers={papers}
                        onPaperSelect={handlePaperSelect}
                        selectedPaperId={selectedPaper}
                        isBusy={isBusy || localBusy}
                        handleSort={handleSort}
                        sortOrder={sortOrder}
                        sortDirection={sortDirection}
                        handleToggleSortDirection={handleToggleSortDirection}
                        handleSmartSort={handleSmartSort}
                        smartSortPlaceholder={smartSortPlaceholder}
                        isSmartSorting={isSmartSorting}
                        hideControls={hideControls}
                        animate={animate}
                        animationDuration={animationDuration}
                    />
                    {!hideDetails && <PapersDisplayRight 
                        selectedPaper={selectedPaper}
                        handleSmartSort={handleSmartSort}
                        smartSortPlaceholder={smartSortPlaceholder}
                        isSmartSorting={isSmartSorting}
                        hideControls={hideControls}
                        animate={animate}
                        animationDuration={animationDuration}
                    />}
                </div>
            )}
        </div>
    );
};

export default PapersDisplay;
export { PapersList, PapersListElement, PaperDetails };
