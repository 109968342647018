import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal_overlay" onClick={onClose}>
      <div className="modal_box" onClick={e => e.stopPropagation()}>
        {children}
        <button 
          onClick={onClose}
          className="refy_button button-secondary modal_close_button"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;