// import { log } from './logger.js';
import * as Sentry from '@sentry/react';


let apiUrl = process.env.REACT_APP_API_URL;
const API_ENV = process.env.NODE_ENV === 'production' ? 'production' : 'development';


// write a function to send post requests
export default async function sendPostRequest(url, body, name, ) {
    try {
          const options = {
            method: 'POST',
            body: body,
            headers: {
                'X-API-Environment': API_ENV,
            },
        };
        console.log(apiUrl)
  
        // If data is not FormData, stringify it and set Content-Type
        if (!(body instanceof FormData)) {
            options.headers = { 
                'Content-Type': 'application/json',
                'X-API-Environment': API_ENV,
            }
        } 
        const response = await fetch(`${apiUrl}${url}`, options);
  
        if (response.ok) {
            const data = await response.json();
            console.log(`${name} POST request successful`);
            return data; 
        } else {
            console.error(`${name} POST request failed`, response, await response.text());
            Sentry.captureException(response);
            return response;
        }
    } catch (error) {
        console.error('Error:', error);
        Sentry.captureException(error);
        return null;
    }
  }
  

export { apiUrl };
