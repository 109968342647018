import * as React from 'react'
import { useUser, useClerk } from '@clerk/clerk-react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUniversity, faUserGraduate, faMicroscope, faSearch, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import posthog from 'posthog-js'

import sendPostRequest from '../scripts/Requests'
import NavBarOld from '../components/NavBarOld'

export default function Onboarding() {
  const [error, setError] = React.useState('')
  const { user } = useUser()
  const clerk = useClerk()
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    institution: Yup.string().required('Institution is required'),
    position: Yup.string().required('Position is required'),
    field: Yup.string().required('Field is required'),
    researchInterests: Yup.string().required('Research interests are required'),
  })

  const initialValues = {
    institution: '',
    position: '',
    field: '',
    researchInterests: '',
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setError('') // Clear any previous errors
    
    try {
      const payload = {
        userId: user.id,
        userEmail: user.primaryEmailAddress.emailAddress,
        userName: user.fullName,
        metadata: {
          onboardingComplete: true,
          institution: values.institution,
          position: values.position,
          field: values.field,
          researchInterests: values.researchInterests,
        }
      }
      
      // Use the updateUserMetadata method
      const response = await sendPostRequest('/backend/update-user-metadata', JSON.stringify(payload), 'updating-user-metadata')
      
      console.log('Update result:', response)

      if (!response) {
        throw new Error('Failed to update user metadata')
      }
      
      // Refresh the session to reflect the updated metadata
      posthog.capture('onboarding_complete', {
        user_id: user.id,
        user_email: user.primaryEmailAddress.emailAddress,
        user_name: user.fullName,
        metadata: payload.metadata
      })
      await clerk.session.reload()
      
      navigate('/demo')
    } catch (err) {
      console.error('Error updating user metadata:', err)
      setError(`There was an error updating the user metadata: ${err.message || 'Unknown error'}`)
    }
    setSubmitting(false)
  }

  return (
    <div className="onboarding-page">
    <NavBarOld hideLinks={true}/>
    <div className="onboarding-container">


      <motion.h1 
        className="onboarding-title"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Hi, <i>{user.firstName || 'there'}</i>!<br/>
        Tell us about yourself
      </motion.h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form className="onboarding-form">
            <motion.div 
              className="form-group"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0.1 }}
            >
              <label htmlFor="institution"><FontAwesomeIcon className="form-group-icon" icon={faUniversity} /> Current Institution</label>
              <Field type="text" name="institution" placeholder="Enter your institution" />
              <ErrorMessage name="institution" component="div" className="error" />
            </motion.div>


            <div className="position-field">

            <motion.div 
              className="form-group"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0.2 }}
            >
                <label htmlFor="position"><FontAwesomeIcon className="form-group-icon" icon={faUserGraduate} /> Position</label>
                <div className="select-wrapper">
                  <Field as="select" name="position">
                    <option value="">Select a position</option>
                    <option value="student">Student</option>
                    <option value="phd_student">PhD Student</option>
                    <option value="postdoc">Postdoc</option>
                    <option value="faculty">Faculty</option>
                    <option value="employee">Employee</option>
                    <option value="other">Other</option>
                  </Field>
                </div>
                <ErrorMessage name="position" component="div" className="error" />
            </motion.div>

            <motion.div 
              className="form-group"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0.3 }}
            >
              <label htmlFor="field"><FontAwesomeIcon className="form-group-icon" icon={faMicroscope} /> Field of Study</label>
              <div className="select-wrapper">
                <Field as="select" name="field">
                  <option value="">Select a field</option>
                  <option value="mathematics">Mathematics</option>
                  <option value="engineering">Engineering</option>
                  <option value="electrical_engineering">Electrical Engineering</option>
                  <option value="biotech">Biotechnology</option>
                  <option value="computer_science">Computer Science</option>
                  <option value="physics">Physics</option>
                  <option value="chemistry">Chemistry</option>
                  <option value="biology">Biology</option>
                  <option value="neuroscience">Neuroscience</option>
                  <option value="medicine">Medicine</option>
                  <option value="psycology">Psycology</option>
                  <option value="economics">Economics</option>
                  <option value="finance">Finance</option>
                  <option value="marketing">Marketing</option>
                  <option value="management">Management</option>
                  <option value="accounting">Accounting</option>
                  <option value="law">Law</option>
                  <option value="philosophy">Philosophy</option>
                  <option value="history">History</option>
                  <option value="literature">Literature</option>
                  <option value="other">Other</option>
                </Field>
              </div>
              <ErrorMessage name="field" component="div" className="error" />
            </motion.div>
            </div>
            <motion.div 
              className="form-group"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0.4 }}
            >
              <label htmlFor="researchInterests"><FontAwesomeIcon className="form-group-icon" icon={faSearch} /> Research Interests</label>
              <Field as="textarea" name="researchInterests" placeholder="Briefly describe your research interests" />
              <ErrorMessage name="researchInterests" component="div" className="error" />
            </motion.div>

      

            {error && <p className="error">Error: {error}</p>}
            <motion.button 
              type="submit" 
              disabled={isSubmitting || !(dirty && isValid)}
              className={`submit-button refy_button button-primary ${(isSubmitting || !(dirty && isValid)) ? 'disabled' : ''}`}
              whileHover={{ scale: dirty && isValid && !isSubmitting ? 1.05 : 1 }}
              whileTap={{ scale: dirty && isValid && !isSubmitting ? 0.95 : 1 }}
            >
              Submit <FontAwesomeIcon icon={faPaperPlane} />
            </motion.button>
          </Form>
        )}
      </Formik>
    </div>
    </div>
  )
}