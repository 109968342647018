import React from 'react';

// Inputs are controlled, usage:
// <SearchParameters 
// startYear={this.state.startYear}
// endYear={this.state.endYear}
// limit={this.state.limit}
// onParameterChange={this.handleParameterChange}
// isVisible={this.state.showDescription}
// />

class SearchParameters extends React.Component {
    handleYearChange = (event, defaultValue) => {
        let value = parseInt(event.target.value);
        const currentYear = new Date().getFullYear();
        if (isNaN(value) || value <= 1800 || value > currentYear) {
            console.log(`Year must be a number between 1800 and ${currentYear}, got:`, event.target.value);
            value = defaultValue;
        }
        return value;
    }

    handleStartYearChange = (event) => {
        const value = this.handleYearChange(event, 1900);
        this.props.onParameterChange('startYear', value);
    }

    handleEndYearChange = (event) => {
        const value = this.handleYearChange(event, new Date().getFullYear());
        this.props.onParameterChange('endYear', value);
    }

    handleLimitChange = (event) => {
        let value = parseInt(event.target.value);
        
        if (isNaN(value) || value <= 0 || value > 150) {
            console.log('Limit must be between 1 and 150, got:', event.target.value);
            value = 100;
        }
        this.props.onParameterChange('limit', value);
    }

    render() {
        const 
        opacity = this.props.isVisible ? 1 : 0;
        return (
            <div className="search-params" style={{ opacity: opacity }}>
                <div className="search-params-content">
                    <div className="search-params-dates">
                        <div className="search-params-title">Publication date range:</div>
                        <div className="search-params-date-range">
                            <input type="number" placeholder="Start year" 
                                    className="input-year-start"
                                    value={this.props.startYear} 
                                    onChange={(e) => this.props.onParameterChange('startYear', e.target.value)}
                                    onBlur={this.handleStartYearChange} />
                            
                            <input type="number" placeholder="End year" 
                                    className="input-year-end"
                                    value={this.props.endYear} 
                                    onChange={(e) => this.props.onParameterChange('endYear', e.target.value)}
                                    onBlur={this.handleEndYearChange} />
                        </div>
                    </div>
                    <div className="search-params-limit">
                        <div className="search-params-title"># results (max 150):</div>
                        <input type="number" placeholder="Limit" 
                                value={this.props.limit} 
                                onChange={(e) => this.props.onParameterChange('limit', e.target.value)}
                                onBlur={this.handleLimitChange} />
                    </div>
                </div>
            </div>
        );
    }
}

export default SearchParameters;