import React, { Component } from 'react';
import { toast } from 'react-toastify';
import posthog from 'posthog-js'
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';

import sendPostRequest, { apiUrl } from '../scripts/Requests';
import { log } from '../scripts/logger';
import Button from '../elements/Button';
import socket from '../scripts/socket';

class BibtexLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileName: '',
            opacity: 0,
            nReferences: 0,
            isBusy: false,
            isLoading: false
        };
    }

    componentDidMount() {
        socket.on('upload_success', this.handleUploadSuccess);
        socket.on('description_success', this.handleDescriptionSuccess);
        socket.on('embeddings_success', this.handleEmbeddingsSuccess);
        socket.on('error', this.handleSocketError);
    }

    componentWillUnmount() {
        socket.off('upload_success', this.handleUploadSuccess);
        socket.off('description_success', this.handleDescriptionSuccess);
        socket.off('embeddings_success', this.handleEmbeddingsSuccess);
        socket.off('error', this.handleSocketError);
    }

    handleUploadSuccess = (data) => {
        console.log("Upload success", data);
        this.props.setViewState({
            bibName: data.filename,
        });
        this.setState({fileName: data.filename, nReferences: data.n_references, isBusy: false, opacity: 1});
        this.props.onLoadEnd && this.props.onLoadEnd();
        this.props.addOrUpdateStatus('bib-loading', 'File uploaded', 'done');
        this.props.addOrUpdateStatus('bib-processing', 'Processing references...', 'busy');
        posthog.capture('try_app_bib_load_success', {file_size: data.file_size, n_references: data.n_references});
    }

    handleDescriptionSuccess = (data) => {
        console.log("Description success", data.description);
        this.props.setViewState({
            description: data.description,
            searchQueries: data.search_queries,
            smart_sort_placeholder: data.smart_sort_prompt
        });
    }

    handleEmbeddingsSuccess = (data) => {
        console.log("Embeddings success", data);
        this.props.setViewState({
            references: data.result
        });
        this.props.addOrUpdateStatus('bib-processing', 'References processed', 'done');
    }

    handleSocketError = (error) => {
        console.error(error.error);
        toast.error(
            <div>
            <h3><b>Something went wrong!</b></h3>
            Please try again in a moment. If the problem persists, please contact us.
            <br/>
            Error: {error.error}
          </div>
            , {autoClose: 5000, closeOnClick: true, pauseOnHover: true});
    }

    getExampleFile = async () => {
        log("Fetching default bibliography");
        const response = await fetch(`${apiUrl}/backend/load-default-bib`);
        this.props.addOrUpdateStatus('bib-loading', 'Loading example .bib...', 'busy');
        return response;
    }

    getUserFile = async (file) => {
        log("Uploading user file", file);
        if (!file) {
            throw new Error('No file selected');
        }

        const maxSizeInBytes = 1000 * 1024; // 1 MB
        if (file.size > maxSizeInBytes) {
            toast.error(
                <div>
                  <h3><b>File Size Limit Exceeded</b></h3>
                  <p>Your file is too large. Please select a file smaller than 1 MB.</p>
                </div>
              ,
              {autoClose: 5000, closeOnClick: true,pauseOnHover: true,}
            );
            posthog.capture('try_app_bib_load_user_error', {error: "file_too_large", file_size: file.size, limit: maxSizeInBytes});
            return null;
        }

        log("Uploading user file", file);
        this.props.addOrUpdateStatus('bib-loading', 'Uploading .bib file...', 'busy');
        posthog.capture('try_app_bib_load_user', file);
        const formData = new FormData();
        formData.append('file', file);

        const response = await sendPostRequest(
            `/backend/upload-user-file`,
            formData,
            'Uploading user file'
        );
        return response;
    }

    handleUploadBib = async (file, loadDefault) => {
        this.setState({ isBusy: true, isLoading: true });
        this.props.onLoadStart && this.props.onLoadStart();

        try {
            if (loadDefault) {
                await this.getExampleFile();
            } else {
                await this.getUserFile(file);
            }
        } catch (error) {
            console.error(error);
            posthog.capture('try_app_bib_load_error', {error: error.message});
        }
        this.setState({ isBusy: false });
        // isLoading will be set to false when searchReady becomes true
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.ViewState.searchReady && this.props.ViewState.searchReady) {
            this.setState({ isLoading: false });
        }
    }

    reset = () => {
        this.props.onReset && this.props.onReset();
        this.setState({ 
            fileName: '', 
            opacity: 0, 
            nReferences: 0, 
            isBusy: false 
        });

        this.props.setViewState({ 
            references: [],
            bibName: '',
            description: '',
            searchQueries: [],
            smart_sort_placeholder: '',
        });

        // Use requestAnimationFrame to ensure the state update is propagated quickly
        requestAnimationFrame(() => {
            this.setState({ opacity: 1 });
        });
    }

    onButtonClick = (file, loadDefault) => {
        if (this.hasFile()) {
            // If there's already a file loaded, just reset
            this.reset();
        } else {
            // Only handle upload when there's no file loaded
            this.handleUploadBib(file, loadDefault);
        }
    }

    hasFile = () => {
        return this.state.fileName !== '';
    }

    renderUploadButtons() {
        const { isBusy } = this.state;
        const hasFile = this.hasFile();
        return (
            <div className="upload-buttons-container">
                <motion.div
                    layout
                    className="upload-buttons-container-inner"
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                >
                    {!hasFile && (
                        <input
                            type="file"
                            id="file-upload-input"
                            onChange={(e) => this.onButtonClick(e.target.files[0], false)}
                            accept='.bib'
                            style={{ display: 'none' }}
                        />
                    )}
                    <Button
                        className={`button-${hasFile ? 'secondary' : 'secondary'} bib-upload-button button-wide`}
                        buttonText={hasFile ? "Load a new file" : "Upload your library"}
                        isToggle={false}
                        icon={hasFile ? "fa-solid fa-undo" : "fa-solid fa-file-upload"}
                        onClick={hasFile 
                            ? () => this.onButtonClick(null, false) 
                            : () => document.getElementById('file-upload-input').click()
                        }
                        disabled={isBusy}
                    />
                    {this.renderStartSearchButton()}
                </motion.div>
                
                <LayoutGroup>
                    <motion.div
                        layout
                        className="file-info-container"
                        style={{ height: 30 }} // Adjust this value based on your design
                    >
                        <AnimatePresence>
                                <motion.div
                                    key="example-button"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3, delay: 0, ease: "easeInOut", exit: { delay: 0.5 } }}
                                >
                                    {!hasFile ? <Button
                                        className="text-button"
                                        buttonText="or use an example"
                                        onClick={() => this.onButtonClick(null, true)}
                                        isToggle={false}
                                        disabled={isBusy}
                                    /> : this.renderFileInfo()  }
                                </motion.div>
                          
                        </AnimatePresence>
                    </motion.div>
                </LayoutGroup>
            </div>
        );
    }

    renderStartSearchButton = () => {
        const { isLoading } = this.state;
        const { searchReady } = this.props.ViewState;

        return (
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.1 }}
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5rem' }}
            >
                <Button
                    className={`button-accent ta-start-button`}
                    enabled={searchReady}
                    icon={isLoading ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-magnifying-glass"}
                    onClick={this.props.handleStartSearch}
                />
            </motion.div>
        );
    }

    renderFileInfo = () => {
        const { fileName, nReferences } = this.state;
        return (
            <div className="loaded-file-name-container">
                <div className="loaded-file-name-label">File loaded:</div>
                <div className="loaded-file-name">{fileName}</div>
                <div className="loaded-file-size">({nReferences} papers)</div>
            </div>
        );
    }

    render() {
        return (
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
            >
                {this.renderUploadButtons()}
            </motion.div>
        );
    }
}

export default BibtexLoader;