import React, { useState, useEffect } from 'react';
import posthog from 'posthog-js';
import {toast} from 'react-toastify';
import { useUser } from "@clerk/clerk-react";


import Survey from '../components/Survey';
import Payment from '../components/Payment';
import sendPostRequest from '../scripts/Requests';
import NavBarOld from '../components/NavBarOld';

function DemoOver({ userId, user }) {
  const [surveyTitle, setSurveyTitle] = useState('');
  const [surveyID, setSurveyID] = useState('');
  const [isTrialSubscriber, setIsTrialSubscriber] = useState(false);
  const { user: clerkUser } = useUser();

  useEffect(() => {
    if (posthog) {
      posthog.getActiveMatchingSurveys((surveys) => {
        if (surveys.length > 0) {
          const survey = surveys[0];
          setSurveyID(survey.id);
          setSurveyTitle(survey.questions[0].question);
          console.log("Survey ID:", survey.id);
          console.log("Survey Title:", survey.questions[0].question);
        }
      });
    }
  }, []);

  useEffect(() => {
    const checkFeedbackStatus = async () => {
      const storageKey = `feedback_given_${userId}`;
      const hasGivenFeedback = localStorage.getItem(storageKey);

      if (!hasGivenFeedback || hasGivenFeedback === 'false') {
        localStorage.removeItem(storageKey);
        posthog.capture('demo_completed', { userId });
      } 
    };
    checkFeedbackStatus();
  }, [userId]);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const response = await sendPostRequest(
          '/backend/get-user-subscription',
          JSON.stringify({ userId }),
          'getUserSubscription'
        );
        setIsTrialSubscriber(response.subscriptionType === 'trial');
        // Add PostHog capture for subscription status
        posthog.capture('subscription_status_checked', {
          userId,
          isTrialSubscriber: response.subscriptionType === 'trial'
        });
      } catch (error) {
        console.error('Error fetching subscription status:', error);
        // Add PostHog capture for subscription status check error
        posthog.capture('subscription_status_check_error', {
          userId,
          error: error.message
        });
      }
    };

    checkSubscriptionStatus();
  }, [userId]);

  /* --------------------------------- survery -------------------------------- */
  const handleDismiss = () => {
    console.log("Survey dismissed!");
    localStorage.setItem(`hasInteractedWithSurvey_${surveyID}`, 'true');
    posthog.capture("survey_dismissed", {
      $survey_id: surveyID
    });
    
  };

  const handleSubmit = (value) => {
    console.log("User submitted:", value);
    localStorage.setItem(`hasInteractedWithSurvey_${surveyID}`, 'true');  
    toast.success("Thank you for your feedback!");
    posthog.capture("survey sent", {
      $survey_id: surveyID, // required
      $survey_response: value // required
    })
  };

  useEffect(() => {
    if (posthog && surveyID) {
      posthog.capture("survey shown", {
        $survey_id: surveyID // required
      })
    }
  }, [surveyID, posthog])

  /* ---------------------------------- trial --------------------------------- */
  const onPaymentSuccessful = async () => {
    console.log("Payment successful!");
    const outcome = await updateUserSubscription();
    if (outcome) {
      toast.success("Payment successful! You now have been signed up for the trial.");
      // Add PostHog capture for successful payment and subscription update
      posthog.capture('trial_subscription_started', { userId });
    } else {
      toast.error("Payment successful, but there was an error updating your subscription.");
      // Add PostHog capture for payment success but subscription update failure
      posthog.capture('trial_subscription_update_failed', { userId });
    }
  };

  const updateUserSubscription = async () => {
    try {
        const payload = {
            userId: userId,
            subscriptionType: 'trial'
        }

        const response = await sendPostRequest(
            '/backend/update-subscription', 
            JSON.stringify(payload), 
            'updateUserSubscription'
        );

      
      if (response.message) {
        console.log('Subscription updated:', response);
        return true;
      } else {
        console.log('Failed to update subscription:', response);
        // Add PostHog capture for subscription update failure
        posthog.capture('subscription_update_failed', { userId });
        return false;
      }
    } catch (error) {
      console.error('Error updating subscription:', error);
      // Add PostHog capture for subscription update error
      posthog.capture('subscription_update_error', { userId, error: error.message });
      return false;
    }
  };

  const onPaymentError = (error) => {
    console.log("Payment error:", error);
    toast.error("Payment error: " + error);
    // Add PostHog capture for payment error
    posthog.capture('payment_error', { userId, error: error.message });
  };

  return (
    <>
    <NavBarOld />
    <div className="demo-over">
      <div className="demo-over-content">
        {isTrialSubscriber ? (
          <div style={{"textAlign":"center"}}>
            <h1>Thank you for signing up for the trial!</h1>
            <p>Hi <b>{clerkUser.firstName}</b>, we're excited to have you on board for the LitSearch trial.</p>
            <p>Once LitSearch is launched, you will have full access to the platform for 3 months.</p>
            <p>If you have any questions or feedback during your trial, please don't hesitate to reach out to us.</p>
          </div>
        ) : (
          <>
            <h1>Thank you for exploring LitSearch!</h1>
            <p>Hi <b>{clerkUser.firstName}</b>, you've completed all your free searches. We hope you found the experience valuable!</p>
            <p>If LitSearch has sparked your interest, we have an <span className="highlight">exclusive offer</span> for you:</p>
            <div className="offer">
              <h2>🚀 Limited Time Offer</h2>
              <p>Sign up now for just $1 and unlock <strong>3 months of full access</strong> to LitSearch after launch!</p>
            </div>
            <p>Don't miss this opportunity to revolutionize your research process. Your future self will thank you! 📚💡</p>
            <Payment 
              title="Get 3 Months Access for $1"
              onPaymentSuccessful={onPaymentSuccessful} 
              onPaymentError={onPaymentError} 
            />
          </>
        )}
      </div>
      <div className="demo-over-content">
        <h3>We'd love your feedback!</h3>
        {surveyID && surveyTitle && (
          <Survey
            title={surveyTitle}
            onDismiss={handleDismiss}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    </div>
    </>
  );
}

export default DemoOver;