import React from 'react';

const Button = ({ className, 
    buttonText, 
    onClick, 
    enabled = true, 
    icon = '', 
    buttonInput=null, 
    busy = false,
    style = {}
  }) => {

  const handleClick = () => {
    if (enabled) {
      onClick && onClick();  // Call the provided onClick callback
    }
  };

  return (
      <label 
          className={`${className}  ${enabled ? 'active' : 'disabled'} ${busy ? 'busy' : ''} refy_button non-selectable-text`} 
          onClick={handleClick}
          style={style}
        >
        {icon && <i className={`fa ${icon}`} style={{ marginRight: '8px' }}></i>}
        {buttonInput && buttonInput}
        <span className="button-text">{buttonText}</span>
      </label>
    
  );
};

export default Button;
