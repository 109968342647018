import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

function Survey({ title, onDismiss, onSubmit }) {
  const [textInput, setTextInput] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);
  const handleTextChange = (event) => {
    setTextInput(event.target.value);
  }

  const handleSubmit = () => {
    onSubmit(textInput);
    setIsSubmitted(true);
  }

  const handleDismiss = () => {
    onDismiss();
    setIsDismissed(true);  // so that it hides
  }

  return (
    <div className="survey">
      
      {!isSubmitted && !isDismissed ? (
        <>
          <div>
            <h2>{title}</h2>
            <textarea
              value={textInput}
              onChange={handleTextChange}
              placeholder="Share your thoughts with us! How useful did you find LitSearch? What can we improve?"
              rows={5}
              cols={50}
            />
          </div>
          <div className="survey-buttons">
            <button className="refy_button button-secondary" onClick={handleDismiss}>Dismiss</button>
            <button 
              className={`refy_button button-primary ${!textInput.trim() ? 'disabled' : ''}`} 
              onClick={handleSubmit}
              disabled={!textInput.trim()}
            >
              Submit
            </button>
          </div>
        </>
      ) : (
        <div className="thank-you-message">
          {isSubmitted ? "Thank you for your feedback!" : "We hope to see you again soon!"}
        </div>
      )}
    </div>
  );
}

function RatingSurvey({ title, onDismiss, onSubmit }) {
  const [rating, setRating] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleRatingClick = (value) => {
    setRating(value);
  }

  const handleSubmit = () => {
    if (rating !== null) {
      onSubmit({ rating });
      setIsSubmitted(true);
    }
  }

  const surveyVariants = {
    hidden: { opacity: 0, y: 50, scale: 0.9 },
    visible: { 
      opacity: 1, 
      y: 0, 
      scale: 1,
      transition: { 
        type: "spring",
        damping: 15,
        stiffness: 100
      }
    },
    exit: { 
      opacity: 0, 
      y: 50, 
      scale: 0.9,
      transition: { 
        duration: 0.2 
      }
    }
  };

  return (
    <AnimatePresence>
      {!isSubmitted && (
        <motion.div 
          className="survey popup-survey"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={surveyVariants}
        >
          <h2>{title}</h2>
          <div className="rating-container">
            <label>How useful are the recommended papers?</label>
            <div className="rating-buttons">
              {[...Array(11)].map((_, i) => (
                <button
                  key={i}
                  className={`rating-button ${rating === i ? 'selected' : ''}`}
                  onClick={() => handleRatingClick(i)}
                >
                  {i}
                </button>
              ))}
            </div>
            <div className="rating-labels">
              <span>Not really</span>
              <span>Very useful!</span>
            </div>
          </div>
          <div className="survey-buttons">
            <button className="refy_button button-secondary" onClick={onDismiss}>Dismiss</button>
            <button 
              className={`refy_button button-primary ${rating === null ? 'disabled' : ''}`} 
              onClick={handleSubmit}
              disabled={rating === null}
            >
              Submit
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Survey;

export { RatingSurvey };