import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { SignUp } from '@clerk/clerk-react';
import Typewriter from 'typewriter-effect';
import NavBarOld from '../components/NavBarOld';
import { useMediaQuery } from 'react-responsive';
import posthog from 'posthog-js';
import { useLocation } from 'react-router-dom';
import { useClerk } from '@clerk/clerk-react';

const DemoLanding = () => {
	const location = useLocation();
	const { client } = useClerk();
	const [isFlipped, setIsFlipped] = useState(false);
	const [showSignUp, setShowSignUp] = useState(() => {
		// Check if the current session is the result of an OAuth flow
		return location.hash.includes('#/sso-callback');
	});
	const isMobile = useMediaQuery({ maxWidth: 1200, maxDeviceWidth: 1200 });
	const signUpRef = useRef(null);
    const [ctaButtonText, setCtaButtonText] = useState('Try Lit Search')
    const [ctaButtonIcon, setCtaButtonIcon] = useState('fa-solid fa-arrow-right')

    console.log(location.search)

	useEffect(() => {
		console.log('Current path:', location.pathname);
		console.log('Current hash:', location.hash);
		console.log('Show sign up:', showSignUp);
	}, [location.pathname, location.hash, showSignUp]);

	useEffect(() => {
		const flipInterval = setInterval(() => setIsFlipped(prev => !prev), 8000);
		if (showSignUp) {
			setCtaButtonText('Login');
			setCtaButtonIcon('fa-solid fa-arrow-up');
		}
		return () => clearInterval(flipInterval);
	}, [showSignUp]);

	const flipVariants = {
		initial: { x: 0, opacity: 1 },
		exit: { x: '100px', opacity: 0 },
		enter: { x: '100px', opacity: 0 },
		center: { x: 0, opacity: 1 },
	};

	const CardContent = ({ isBack, title }) => (
		<div 
			className={`paper-flip-${isBack ? 'back' : 'front'}`}
			style={{ 
				backgroundImage: `url("/landing_${isBack ? 'after' : 'before'}.png")`,
				backgroundSize: 'cover',
				backgroundPosition: 'center bottom',
				backgroundRepeat: 'no-repeat',
				width: '100%',
				height: '100%',
			}}
		>
			<div className="paper-flip-title" dangerouslySetInnerHTML={{ __html: title }}></div>
		</div>
	);

	const DesktopCard = ({ isBack }) => (
		<motion.div
			key={isBack ? 'back' : 'front'}
			className="paper-flip-container"
			variants={flipVariants}
			initial="enter"
			animate="center"
			exit="exit"
			transition={{ duration: 0.8, type: "tween" }}
		>
			<CardContent 
				isBack={isBack} 
				title={isBack ? 'We make sure you find the <strong>one</strong> you need.' : 'Over 10,000 new papers are published every week.'}
			/>
		</motion.div>
	);

	const MobileCard = ({ isBack }) => (
		<motion.div
			key={isBack ? 'back' : 'front'}
			className="paper-flip-container mobile-card"
			variants={flipVariants}
			initial="enter"
			animate="center"
			exit="exit"
			transition={{ duration: 0.8, type: "tween" }}
		>
			<CardContent 
				isBack={isBack}
				title={isBack ? 'We make sure you find the <strong>one</strong> you need.' : 'Over 10,000 new papers are published every week.'}
			/>
			</motion.div>
	);

	const handleStartDemo = () => {
        posthog.capture('demo_landing_signup_clicked')
		setShowSignUp(true);
		if (isMobile) {
			setTimeout(() => {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			}, 500);
            setCtaButtonText('Login')
            setCtaButtonIcon('fa-solid fa-arrow-up')
        } else {
            setCtaButtonText('Sign up')
        }
	};

	const SignupElement = (
		<motion.div 
			className="ta-login-manager-button"
			initial={{ opacity: 0, y: 100 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.8, delay: 0.4 }}
			ref={signUpRef}
		>
			<SignUp />
		</motion.div>
	);

	return (
		<div className="demo-landing">
			<NavBarOld hideLinks={true} />
			<div className="ta-login-manager">
				<motion.div 
					className="ta-login-manager-illustration"
					initial={{ opacity: 0, y: 50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.8, delay: 0.4 }}
				>
					<AnimatePresence initial={false}>
						{!showSignUp && (
							isMobile ? (
								<>
									{!isFlipped && <MobileCard isBack={false} />}
									{isFlipped && <MobileCard isBack={true} />}
								</>
							) : (
								<>
									{!isFlipped && <DesktopCard isBack={false} />}
									{isFlipped && <DesktopCard isBack={true} />}
								</>
							)
						)}
					</AnimatePresence>
					<AnimatePresence>
							{showSignUp && SignupElement}
					</AnimatePresence>
				</motion.div>

				<motion.div 
					className="ta-login-manager-description"
					initial={{ opacity: 0, x: -50 }}
					animate={{ opacity: 1, x: 0 }}
					transition={{ duration: 0.8, delay: 0.2 }}
				>
					<div className="ta-login-manager-description-inner">
						<h1 className="ta-login-title">
							<Typewriter
								options={{
									strings: ['Find your next best idea'],
									autoStart: true,
									loop: true,
									delay: 25,
									pauseFor: 5000 // Pause for 5 seconds before repeating
								}}
							/>
						</h1>
						<p className="ta-login-subtitle">
							Lit Search: Revolutionizing the way you explore and uncover groundbreaking research.
						</p>
						<ul className="ta-login-features">
							<li><span className="highlight">Precision-driven results:</span> instantly surface the most impactful papers tailored to your research interests</li>
							<li><span className="highlight">Effortless discovery:</span> say goodbye to hours of manual searching - let our AI do the heavy lifting</li>
							<li><span className="highlight">Adaptation to you:</span> as your research evolves, our system evolves with you, ensuring you're always at the cutting edge</li>
						</ul>
						<motion.button 
							className={`ta-login-cta refy_button button-primary ${showSignUp ? 'disabled' : ''}`}
							whileHover={{ scale: 1.05 }}
							whileTap={{ scale: 0.95 }}
							onClick={handleStartDemo}
						>
							{ctaButtonText} <i style={{marginLeft: '0.5rem'}} className={ctaButtonIcon}></i>
						</motion.button>
						<p className="ta-login-trial">Sign up now for 6 free credits and exclusive early access to new Lit Search features!</p>
					</div>
				</motion.div>
			</div>
		</div>
	);
};

export default DemoLanding;