import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import Button from '../elements/Button';

/* -------------------------------------------------------------------------- */
/*                                 SMART SORT                                 */
/* -------------------------------------------------------------------------- */

const SmartSortToggle = ({ enabled, showTextArea, setShowTextArea }) => {
    const className = enabled ? '' : 'disabled'
    return (
        <motion.button
            className={`refy_button smart-sort-button ${className}`}
            disabled={!enabled}
            onClick={() => setShowTextArea(!showTextArea)}
            whileTap={{ scale: 0.95 }}
        >
            Smart Sort
            <FontAwesomeIcon 
                icon={showTextArea ? faChevronUp : faChevronDown} 
                className="smart-sort-chevron"
            />
        </motion.button>
    );
};



const SmartSortTextArea = ({ ViewState, handleSmartSort, showTextArea, setShowTextArea }) => {
    const [inputValue, setInputValue] = useState('');
    const [busy, setBusy] = useState(false);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    }

    const handleSort = async () => {
        if (busy || inputValue.length === 0) return;

        try {
            setBusy(true);
            await handleSmartSort(inputValue);
            setShowTextArea(false);
            setInputValue('');
        } finally {
            console.log('Smart sort finished');
            setBusy(false);
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSort();
        }
    }

    const buttonActive = busy || inputValue.length === 0;
    const buttonClassName = buttonActive ? 'disabled' : '';
    return (
        <AnimatePresence>
            {showTextArea && (
                <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="smart-sort-textarea-container"
                >
                    <textarea
                        id="ta-smart-sort"
                        placeholder="Enter your smart sort criteria..."
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                    />
                    <button onClick={handleSort} className={buttonClassName}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

/* -------------------------------------------------------------------------- */
/*                                 SORT BUTTONS                               */
/* -------------------------------------------------------------------------- */

const SortButtons = ({ viewState, papers, onSort, sortOrder, sortDirection, onToggleSortDirection, isBusy, hidden=false, handleSmartSort, }) => {
    const [showTextArea, setShowTextArea] = useState(false);
    const enabledButtonClass = papers.length > 0 && !isBusy ? "" : "disabled";
    const opacity = hidden ? 0 : 1;
    const results = viewState.searchResults;
    const searchOngoing = viewState.showSearchOngoing;
    const enabled = results.length > 0 && !searchOngoing;

    return (
        <motion.div 
            className="sort-controls-wrapper"
            animate={{
                marginBottom: showTextArea ? '0.5rem' : '',
            }}
            transition={{ duration: 0.3 }}
        >
            <div 
                className="sort-buttons-container" 
                style={{ opacity: opacity }}
            >
                <SmartSortToggle 
                    enabled={enabled}
                    showTextArea={showTextArea}
                    setShowTextArea={setShowTextArea}
                />
                <div className="sort-buttons">
                    <button className={`left-button ${enabledButtonClass} ${sortOrder === 'relevance' ? 'active' : ''}`} onClick={() => onSort('relevance', sortDirection)}>Relevance</button>
                    <button className={`middle-button ${enabledButtonClass} ${sortOrder === 'year' ? 'active' : ''}`} onClick={() => onSort('year', sortDirection)}>Year</button>
                    <button className={`right-button ${enabledButtonClass} ${sortOrder === 'citations' ? 'active' : ''}`} onClick={() => onSort('citations', sortDirection)}>Citations</button>
                {/* </div>
                <div className="sort-buttons"> */}
                    <div style={{width: '1rem'}}/>
                    <button 
                        className={`left-button ${enabledButtonClass} ${sortDirection === 'asc' ? 'active' : ''}`} 
                        onClick={() => onToggleSortDirection('asc')}
                    >
                        <i class="fa-solid fa-arrow-up-wide-short"></i>
                    </button>
                    <button 
                        className={`right-button ${enabledButtonClass} ${sortDirection === 'desc' ? 'active' : ''}`} 
                        onClick={() => onToggleSortDirection('desc')}
                    >
                        <i class="fa-solid fa-arrow-down-wide-short"></i>
                    </button>
                </div>
            </div>
            <SmartSortTextArea 
                ViewState={viewState}
                handleSmartSort={handleSmartSort}
                showTextArea={showTextArea}
                setShowTextArea={setShowTextArea}
            />
        </motion.div>
    );
};

export { SortButtons };