import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const StatusMessage = ({ message, status, subitem=false }) => {
    const iconClass = {
        busy: 'fa-spinner fa-spin',
        done: 'fa-check',
        error: 'fa-exclamation-triangle'
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className={`status-message ${subitem ? 'subitem' : ''}`}
        >
            <i className={`fa-solid ${iconClass[status]}`}></i>
            <p>{message}</p>
        </motion.div>
    );
};

class StatusDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.statusDisplayRef = React.createRef();
    }

    componentDidUpdate() {
        if (this.statusDisplayRef.current) {
            this.statusDisplayRef.current.scrollTop = this.statusDisplayRef.current.scrollHeight;
        }
    }

    render() {
        const { messages } = this.props;
        return (
            <div 
                className="status-display" 
                ref={this.statusDisplayRef}
            >
                <AnimatePresence>
                    {Object.entries(messages).reverse().map(([key, { text, status, subitem }]) => (
                        <StatusMessage key={key} message={text} status={status} subitem={subitem} />
                    ))}
                </AnimatePresence>
            </div>
        );
    }
}

export default StatusDisplay;