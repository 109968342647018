import "./instrument";
import React from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import * as Sentry from '@sentry/react';
import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn, useUser } from '@clerk/clerk-react'

import TryAppWrapper from './pages/TryApp';
import DemoLanding from './pages/DemoLanding';
import DemoOver from './pages/DemoOver';
import Onboarding from './pages/Onboarding'; // Import the Onboarding component
import HeadTags from './components/HeadTags';

posthog.init('phc_Khc7dQXuRFS11YMU6hyCzV6qYpXP9YRQPQIJ03fmVZw',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
)

// Import your publishable key
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

const ProtectedRoute = ({ children }) => {
  const { user } = useUser();
  
  if (!user) {
    return <RedirectToSignIn />;
  }
  
  if (!user.publicMetadata.onboardingComplete) {
    return <Navigate to="/onboarding" replace />;
  }
  
  return children;
};

const App = () => {
  return (
    <>
    <HeadTags/>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <PostHogProvider client={posthog}>
        <Router>
          <Routes>
            <Route path="/login" element={
              <>
                <SignedIn>
                  <Navigate to="/demo" replace />
                </SignedIn>
                <SignedOut>
                  <DemoLanding />
                </SignedOut>
              </>
            } />
            <Route
              path="/demo"
              element={
                <ProtectedRoute>
                  <TryAppWrapper />
                </ProtectedRoute>
              }
            />
            <Route
              path="/demo-over"
              element={
                <ProtectedRoute>
                  <DemoOver />
                </ProtectedRoute>
              }
            />
            <Route
              path="/onboarding"
              element={
                <SignedIn>
                  <Onboarding />
                </SignedIn>
              }
            />
            <Route path="/" element={<Navigate to="/login" replace />} />
          </Routes>
        </Router>
      </PostHogProvider>
    </ClerkProvider>
    </>
  );
}

export default Sentry.withProfiler(App);