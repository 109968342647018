import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { SignedIn, SignedOut, SignUp, useUser, useClerk } from "@clerk/clerk-react";
import posthog from 'posthog-js';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';


import BibtexLoader from '../components/BibtexLoader';
import sendPostRequest from '../scripts/Requests';
import PapersDisplay from '../components/PapersDisplay';
import SearchParameters from '../components/SearchParameters';
import socket from '../scripts/socket';
import NavBarOld from '../components/NavBarOld';
import DemoOver from './DemoOver';
import StatusDisplay from '../components/StatusDisplay';
import { RatingSurvey } from '../components/Survey';



class LoadDataAndStartSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileLoaded: false,
            showSearchInstructions: false,
        };
    }

    handleLoadStart = () => {
        this.props.onLoadStart && this.props.onLoadStart();
        this.setState({ fileLoaded: false });
    }

    handleLoadEnd = () => {
        this.props.onLoadEnd && this.props.onLoadEnd();
        this.setState({ fileLoaded: true });
    }


    render() {
      return (
        <div className="try-app-search non-selectable-text">
            <div className="try-app-search-left">
                <div className="try-app-search-header" id="try-app-search-header">
                    <div className="try-app-search-header-icons">
                        <i 
                            className="fa-solid fa-gear" 
                            onClick={() => this.props.setViewState(prevState => ({
                                ...prevState,
                                show_search_parameters: !prevState.show_search_parameters
                            }))}
                        ></i>
                        <i 
                            className="fa-solid fa-question"
                            onClick={() => this.setState({ showSearchInstructions: !this.state.showSearchInstructions })}
                        ></i>
                    </div>
                </div>
                <BibtexLoader 
                    ViewState={this.props.ViewState}
                    setViewState={this.props.setViewState}
                    onLoadStart={this.handleLoadStart}
                    onLoadEnd={this.handleLoadEnd}
                    onReset={this.props.onReset}
                    handleStartSearch={this.props.handleStartSearch}
                    addOrUpdateStatus={this.props.addOrUpdateStatus}
                    removeStatus={this.props.removeStatus}
                />
           
                <AnimatePresence>
                    {this.props.ViewState.show_search_parameters && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <SearchParameters 
                                startYear={this.props.ViewState.parameters.startYear}
                                endYear={this.props.ViewState.parameters.endYear}
                                limit={this.props.ViewState.parameters.limit}
                                onParameterChange={this.props.handleParameterChange}
                                isVisible={true}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
                <AnimatePresence>
                    {this.state.showSearchInstructions && (
                        <motion.div 
                            className="search-instructions"
                            initial={{ opacity: 0, height: 0, padding: 0 }}
                            animate={{ opacity: 1, height: 'auto', padding: '1rem' }}
                            exit={{ opacity: 0, height: 0, padding: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <motion.div 
                                className=""
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.3, duration: 0.3 }}
                            >
                                LitSearch uses a .bib file with papers metadata to do a search for similar papers.<br/><br/>
                                To get started, load a .bib file with papers you've read on a topic of interest. If you don't have one, you can use the example file
                                from our collection. <br/><br/>
                                Press on the <i className="fa-solid fa-gear"></i> button to modify the search parameters. <br/>
                                Then, press on the <i className="fa-solid fa-magnifying-glass"></i> button to start the search.
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
                
            <div className="try-app-search-right"> 
                <StatusDisplay   messages={this.props.ViewState.statusMessages}/>
                
            </div>

        </div>
        );
      }
    }




/* -------------------------------------------------------------------------- */
/*                                  MAIN APP                                  */
/* -------------------------------------------------------------------------- */

class TryApp extends React.Component {
    maxSearches = 3;

    constructor(props) {
        super(props);
        this.initialState = {
            bibName: '',
            references: [],
            description: '',
            searchQueries: [],
            smart_sort_placeholder: "Describe what you'd like to focus on when sorting the recommendations",
            show_search_parameters: false,

            searchReady: false,
            searchStarted: false,
            backgroundSearchStarted: false,
            showSearchOngoing: false,
            
            searchResults: [],
            searchDone: false,
            parameters: {
                startYear: 1900,
                endYear: new Date().getFullYear(),
                limit: 100,
            },
            isBusy: false,
            showAfterSearchBanner: false,

            remainingSearches: 0,
            showDemoOver: false,
            isDemoOver: false, // New state flag
            statusMessages: {},
            showSurvey: false,
        };
        this.state = { ...this.initialState };
    }
    
    addOrUpdateStatus = (key, text, status, subitem=false) => {
        this.setState(prevState => ({
            ...prevState,
            statusMessages: {
                ...prevState.statusMessages,
                [key]: { text, status, subitem }
            }
        }));
    };

    removeStatus = (key) => {
        this.setState(prevState => {
            const { [key]: _, ...remainingMessages } = prevState.statusMessages;
            return {
                ...prevState,
                statusMessages: remainingMessages
            };
        });
    };

    handleParameterChange = (parameter, value) => {
        this.setState({ parameters: { ...this.state.parameters, [parameter]: value } });
    }

    setStates = (newStates) => {
        this.setState(newStates);
    }

    resetSearchStates = () => {
        this.setState({
            bibName: '',
            references: [],
            description: '',
            searchQueries: [],
            searchReady: false,
            searchStarted: false,
            backgroundSearchStarted: false,
            showSearchOngoing: false,
            searchResults: [],
            searchDone: false,
            showAfterSearchBanner: false,
        });
    }

    componentDidMount = async () => {
        console.log('API URL:', process.env.REACT_APP_API_URL);
        socket.on('search_results', this.handleSearchResults);
        socket.on('search_update', this.handleSearchUpdate);
        socket.on('search_completed', this.handleSearchCompleted);
        socket.on('search_error', this.handleSearchError);
        if (this.props.user) {
            await this.handleCheckUser();
            await this.handleCheckUserSearchCounter();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.handleCheckSearchReady(prevState);
    }

    componentWillUnmount() {
        socket.off('search_results', this.handleSearchResults);
        socket.off('search_update', this.handleSearchUpdate);
        socket.off('search_completed', this.handleSearchCompleted);
        socket.off('search_error', this.handleSearchError);
    }

    handleCheckUser = async () => {
        const response = await sendPostRequest(
            '/backend/check-user', 
            JSON.stringify({ 
                user_id: this.props.userId, 
                user_email: this.props.user.emailAddresses[0].emailAddress,
                user_name: this.props.user.fullName,
             }),
            'check-demo-search-counter'
        );
        if (response && response.message) {
            console.log('User checked:', response);
            posthog.identify(
                this.props.userId,
                {
                    email: this.props.user.emailAddresses[0].emailAddress,
                    name: this.props.user.fullName,
                }
            )
            posthog.capture('user-signed-in', {
                user_id: this.props.userId,
                user_email: this.props.user.emailAddresses[0].emailAddress,
                user_name: this.props.user.fullName,
            });
        } else {
            console.error('Error checking user:', response);
            toast.error('Something went wrong, please try again.');
        }
    }

    handleCheckUserSearchCounter = async () => {
        console.log('Checking user search counter for', this.props.userId);
        const response = await sendPostRequest(
            '/backend/get-demo-searches-count', 
            JSON.stringify({ user_id: this.props.userId, 
                user_email: this.props.user.emailAddresses[0].emailAddress,
                user_name: this.props.user.fullName,
             }),
            'check-demo-search-counter'
    );

        if (response) {
            console.log('Remaining searches:', response.remainingSearches, 'for user', this.props.userId);
            this.setState({ 
                remainingSearches: response.remainingSearches,
                isDemoOver: response.remainingSearches <= 0
            });

            if (response.remainingSearches === 0) {
                posthog.capture('user-demo-searches-exhausted', {
                    user_id: this.props.userId,
                    user_email: this.props.user.emailAddresses[0].emailAddress,
                    user_name: this.props.user.fullName,
                });
                this.props.navigate('/demo-over');
            }
        } else {
            console.error('Error checking user search counter:', response);
            this.setState({ 
                remainingSearches: -10,
                isDemoOver: false // Set isDemoOver based on remainingSearches
            });
        }
    }

    handleSearchResults = (data) => {
        this.setState({ searchResults: data.papers });
    }

    handleSearchUpdate = (data) => {
        this.addOrUpdateStatus(data.step, data.message, data.status, true);
    }

    handleSearchError = (data) => {
        console.error('Search error:', data.error);
        toast.error('There was an error during search. Please try again.');
        this.addOrUpdateStatus('search', 'Error during search. Please try again.', 'error');
    }

    handleSearch = async () => {
        if (this.state.isDemoOver) {
            this.props.navigate('/demo-over');
            return;
        }

        // set showSearchOngoing & disable search button
        this.setState({ 
            showSearchOngoing: true, 
            isBusy: true,
            searchReady: false,
        });
        this.addOrUpdateStatus('search', 'Searching...', 'busy');

        // send POST request, results will come back by socket
        const payload = {
            start_year: this.state.parameters.startYear,
            end_year: this.state.parameters.endYear,
            limit: this.state.parameters.limit,
            references: this.state.references,
            search_queries: this.state.searchQueries,
            user_id: this.props.userId,
        }

        console.log('Sending search request', payload);
        try {
            await sendPostRequest(
                '/backend/library-search',
                JSON.stringify(payload),
                'library-search'
            );

        } catch (error) {
            console.error('Error during search:', error);
            toast.error('There was an error during search. Please try again.');
        }

        // update free searches counter
        this.handleCheckUserSearchCounter();

        this.setState({ 
            searchReady: false,
        });
    }



    handleSearchCompleted = (data) => {
        this.setState({ 
            searchDone: true,
            showSearchOngoing: false,
            isBusy: false,
        });
        this.addOrUpdateStatus('search', 'Search done!', 'done');

        // after 5s remove all status messages
        setTimeout(() => {
            this.setState({ statusMessages: {} });

            // scroll to try-app-search-header
            const searchHeader = document.getElementById('try-app-search-header');
            if (searchHeader) {
                setTimeout(() => {
                    searchHeader.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }, 500);
            }
        }, 3000);


        // Show survey after 10 seconds
        if (Math.random() < 0.3) {
          setTimeout(() => {
            this.setState({ showSurvey: true });
          }, 10000);
        }

        posthog.capture('try_app_search_done');
    }


    handleCheckSearchReady = async (prevState) => {
        if (this.state.searchDone) {
            return;
        }
        const searchReady = this.state.references.length > 0 && this.state.searchQueries.length > 0;
        if (searchReady && !prevState.searchReady && !this.state.searchReady) {
            console.log('Search ready:', searchReady);
            this.setState({
                searchReady: true,
            });   
        }
    }

    handleBibtexLoaderReset = () => {
        this.setState({
            searchReady: false,
        });

        // update free searches counter
        this.handleCheckUserSearchCounter();
    }

    handleSurveyDismiss = () => {
      this.setState({ showSurvey: false });
    }

    handleSurveySubmit = (surveyData) => {
      // Send survey data to PostHog
      posthog.capture("survey sent", {
        $survey_id: "0192451e-9fc5-0000-60ea-545106df6ca1", // required
        $survey_response: surveyData.rating, // required
        user_id: this.props.userId,
      });

      // Hide survey
      this.setState({ showSurvey: false });
    }

    render() {
        const pageVariants = {
            initial: { opacity: 0, y: 50 },
            in: { opacity: 1, y: 0 },
            out: { opacity: 0, y: -50 }
        };

        const pageTransition = {
            type: "tween",
            ease: "anticipate",
            duration: 0.5
        };

        return (
            <div className="App">
                <ToastContainer />
                <NavBarOld 
                    userName={this.props.user ? this.props.user.firstName : ''}
                    nSearches={this.state.remainingSearches}
                />
                <AnimatePresence mode="wait">
                    {this.props.user ? (
                        <motion.div
                            key="signed-in-content"
                            initial="initial"
                            animate="in"
                            exit="out"
                            variants={pageVariants}
                            transition={pageTransition}
                        >
                            <SignedIn>
                                <div className="try-app">
                                    {this.state.showDemoOver ? (
                                        <DemoOver userId={this.props.userId} user={this.props.user}/>
                                    ) : (
                                        <>
                                            <div className="ta-search-description-container">
                                                <LoadDataAndStartSearch 
                                                    ViewState={this.state} 
                                                    setViewState={this.setStates} 
                                                    handleStartSearch={this.handleSearch}
                                                    onLoadStart={this.resetSearchStates}
                                                    onReset={this.handleBibtexLoaderReset}
                                                    handleParameterChange={this.handleParameterChange}
                                                    addOrUpdateStatus={this.addOrUpdateStatus}
                                                    removeStatus={this.removeStatus}
                                                />                                               
                                            </div>
    
                                            <div 
                                                className={`try-app-results`}
                                                id="try-app-results"
                                            >
                                                <PapersDisplay 
                                                    viewState={this.state}
                                                    papers={this.state.searchResults}
                                                    isBusy={this.state.isBusy} // Pass the isBusy state to PapersDisplay
                                                    setViewState={this.setStates}
                                                    smartSortPlaceholder={this.state.smart_sort_placeholder}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </SignedIn>
                        </motion.div>
                    ) : (
                        <motion.div
                            key="login-manager"
                            initial="initial"
                            animate="in"
                            exit="out"
                            variants={pageVariants}
                            transition={pageTransition}
                        >
                        </motion.div>
                    )}
                </AnimatePresence>
                <AnimatePresence>
                  {this.state.showSurvey && (
                    <RatingSurvey
                      title="We'd love your feedback!"
                      onDismiss={this.handleSurveyDismiss}
                      onSubmit={this.handleSurveySubmit}
                    />
                  )}
                </AnimatePresence>
            </div>
        );
    }
}

// Add this new component for the loading spinner
const LoadingSpinner = () => (
  <motion.div
    style={{
      width: '50px',
      height: '50px',
      border: '5px solid #e9e9e9',
      borderTop: '5px solid #3498db',
      borderRadius: '50%',
      margin: 'auto',
    }}
    animate={{ rotate: 360 }}
    transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
  />
);

function TryAppWrapper() {
    const { isLoaded, isSignedIn, user } = useUser();
    const { loaded: clerkLoaded } = useClerk();
    const navigate = useNavigate();

    if (!isLoaded || !clerkLoaded) {
        return <LoadingSpinner />;
    }

    if (!isSignedIn) {
        navigate('/login');
        return null;
    }

    return <TryApp user={user} userId={user?.id} navigate={navigate} />;
}

export default TryAppWrapper;